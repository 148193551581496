@media screen and (min-width: 1024px) {
	.hidden-sm {
		display: block;
	}
	.hidden-md {
		display: block;
	}
	.hidden-lg {
		display: none;
	}
	.sidebar-product{
		position: absolute;
		margin-top: 3rem;
		height: fit-content;
	}
	.sidebar-options {
		flex-direction: row;
		align-items: center;
		margin-top: 0;
	}
	.nav-bar {
		max-width: 100%;
		align-items: center;
	}
	.app-name {
		flex: 2;
		padding-left: 2em;
	}
	.navbar-search-tab-container {
		width: 50%;
	}
	.navbar-center {
		flex: 2;
		padding-right: 2em;
	}
	.navbar-right {
		flex: 0.8;
		padding-right: 3em;
	}
	.sidebar-products-option {
		background-color: white;
	}
	.sub-products {
		border: 0;
		border-top: 0.5px solid black;
		justify-content: center;
	}
	.image-container {
		height: calc(100vh - 6em);
	}

	.page-section-title {
		font-size: 1.5em;
	}
	.collection-card-parent {
		height: 50vh;
	}
	.collection-card-name {
		font-size: 1em;
	}
	.collection.page-section .page-section-cards {
		grid-template-columns: repeat(4, 1fr);
	}
	.page-section.best > p:first-child {
		font-size: 1.4em;
	}
	.page-section.best > p {
		font-size: 1em;
	}
	.page-section.best > div {
		height: fit-content;
	}
	.page-section .page-section-cards,
	.loading-container {
		grid-template-columns: repeat(4, 1fr);
	}
	.page-section.best .card-container {
		min-width: 25%;
		height: 20em;
	}
	.page-subsection-title {
		font-size: 1.5em;
	}
	.card-container {
		height: 40vh;
		padding: 0 0.5rem;
	}
	.card-title {
		font-size: 0.8em;
		flex: 3;
		max-height: 32px;
		overflow: hidden;
	}
	.card-price {
		font-size: 0.8em;
		/* flex: 1; */
		/* margin-bottom: 1em; */
	}
	.card-add-btn {
		flex: 0.5;
		padding: 0em 2em;
		font-size: 0.8em;
	}
	.card-details {
		height: 35%;
		padding: 0 1em;
	}
	.options {
		padding: 15px;
		text-align: center;
	}
	.appointment-container > p {
		font-size: 1.6em;
	}
	.appointment-container > section > h2 {
		font-size: 1.5em;
	}
	.appointment-container > section > div,
	.procedure-price {
		font-size: 1.2em;
	}
	.blog-post-details img {
		max-width: 30%;
		height: 10em;
	}
	.product-img-container {
		width: 20%;
	}
	.product-img-container {
		margin: 1em auto;
	}
	.product-desc {
		font-size: 1em;
	}
	.product-details,
	.product-cart-details {
		width: 60%;
	}
	.product-quantity > button,
	.cart-item-price > button {
		flex: 0.5;
	}
	.product-quantity > input {
		flex: 0.5;
	}
	.products-navigation > span {
		font-size: 0.85em;
	}
	.products-navigation > .current-page {
		font-size: 1.2em;
	}
	.login-container,
	.signup-container,
	.side-align-img {
		flex: 1;
		max-width: 46%;
	}
	.side-align-img {
		background-color: rgba(0, 0, 0, 0.6);
		display: flex;
	}
	.side-align-img > img {
		display: block;
		margin: 2em 5em;
		width: calc(100% - 10em);
		max-height: 500px;
		max-width: 100%;
	}
}
