@media screen and (min-width:768px) and (max-width:1023px){
    nav{
        height: 6em;
    }
    .nav-bar{
        padding: 1.5em 2.5em;
    }
    .app-name{
        font-size: 2.2em;
        flex: 3;
    }
    .navbar-icon{
        width: 2.5em;
        height: 2.5em;
    }
    #cart-no{
        font-size: 1em;
        padding: .4em;
        right: -1.2em;
        top: -.5em;
    }
    .cart-icon-container.hidden{
        display: block;
    }
    .navbar-line-1, .navbar-line-2, .navbar-line-3{
        width: 2.5em;
        height: .3em;
        margin: .4em 0;
    }
    .sidebar-section {
        padding-top: 5em;
    }

    .sidebar-profile .username{
        font-size: 1.8em;
    }
    .sidebar-profile .user-email{
        font-size: 1.2em;
    }
    .navbar-search-tab-container{
        top: 5em;
        font-size: 1.5em;
    }
    #cancel {
        font-size: 1em;
    }
    .main-content {
        margin-top: 1.5em;
    }
    .image-container{
        height: 600px;
    }
    .page-section-title{
        font-size: 2.5em;
    
    }
    .collection-card-container {
        height: 30vh;
    }
    .collection-card-name{
        font-size: 1.2em;
    }
    .page-section.best{
        height: 35em;
    }
    .page-section.best > p:first-child{
        font-size: 2em;
    }
    .page-section.best > p{
        font-size: 1em;
    }
    .page-section.best > div{
        max-width: 50em;
        height: 35em;
    }
    .page-section.best .card-container{
        min-width: 50%;
        height: 28em;
        padding: .5em 0;
    }
    .page-subsection-title > span {
        font-size: 1.5em;
    }
    .products-navigation > button{
        width: 1.5em;
        font-size: 2em;
        line-height: 36px;
        padding: .2em 0;
    }
    .products-navigation > span{
        font-size: 1.3em;
    }
    .products-navigation > .current-page{
        font-size: 1.8em;
    }
    .cart-item-container{
        height: 35%;
    }
    .card-price {
        flex: 0.5;
    }
    .card-details{
        height: 45%;
    }
    .card-rating, .card-price{
        font-size: 1.2em;
    }
    
    .card-title, .card-add-btn{
        font-size: 1.2em;
    }
    .card-title {
		flex: 1;
		max-height: 50px;
		overflow: hidden;
	}
    .sidebar-main-options{
        padding: 2em 1em;
        width: calc(100% - 2em);
    }
    .sidebar-profile > img {
        max-width: 15%;
        height: auto;
    }
    .signup{
        font-size: 1em;
    }
    .account-section{
        height: 6em;
    }
    .options{
        padding: 1.2em 1em;
        font-size: 1.5em;
    }
    .login{
        font-size: .9em;
    }
    .login-page, .signup-page {
        height: calc(100vh - (100px + 3em));
    }
    .signup-title, .login-title {
        font-size: 2em;
    }
    .signup-section > label, .login-section > label,
    .signup-section > input, .login-section > input {
        font-size: 1.5em;
    }
    .auth-form > p {
        padding: 1em 0;
        font-size: 1.1em;
    }
    .appointment-container > p {
        font-size: 1.8em;
    }
    .appointment-container > section > div, .procedure-price {
        font-size: 1.5em;
        line-height: 30px;
    }
    .appointment-container h2{
        font-size: 2em;
        margin: .5em 0;
    }
    .appointment-container > section:last-child > h3{
        font-size: 2.5em;
    }
    .appointment-icon{
        width: 8em;
    }
    .appointment-form {
        height: 38em;
    }
    .appointment-form > label {
        font-size: 2em;
        margin: .5em 0;
    }
    .appointment-form > input, .appointment-form > select {
        font-size: 1.8em;
        padding: .5em .5em;
    }
    .appointment-form > button {
        font-size: 1.8em;
    }
    .auth-title {
        font-size: 1.8em;
    }
    .auth-form {
        margin-top: 2em;
    }
    .visible {
        top: 2.5em;
        right: 1em;
        width: 1.8em;
        height: 1.8em;
    }
    .product-name{
        font-size: 1.9em;
    }
    .product-price{
        font-size: 1.4em;
    }
    .product-desc {
        font-size: 1.4em;
    }
    .more {
        font-size: 1.15em;
    }
    .product-quantity > label {
        font-size: 1.6em;
    }
    .product-cart-details button, .product-cart-details input {
        font-size: 1.5em;
    }
    .product-cart-details button{
        padding: 1em 0;
    }
    .blog-name {
        font-size: 2em;
    }
    .blog-post-container {
        height: 18em;
    }
    .blog-post-title {
        font-size: 2em;
    }
    .blog-post-date, .blog-date {
        font-size: 1.2em;
        line-height: 32px;
    }
    .blog-post-details img {
        max-width: 24%;
        height: 10em;
    }
    .blog-post-text {
        max-width: 70%;
        font-size: 1.2em;
        line-height: 28px;
    }
    .blog-title {
        font-size: 1.5em;
    }
    .blog-text {
        font-size: 1.5em;
        line-height: 40px;
    }
    .cart-item-container > img {
        width: 8em;
    }
    .cart-item-name {
        font-size: 1.3em;
    }
    .item-price {
        font-size: 1em;
    }
    .cart-item-price > button, .cart-item-price > input{
        font-size: 1.5em;
    }
    .delete-item-icon > button {
        font-size: 1.2em;
        padding: .5em 1em;
    }
    .cart-total {
        padding: .5em 0;
        font-size: 1.5em;
    }
    .proceed-btn-container button, 
    .payment-container button{
        font-size: 1.5em;
    }
    .signup-container > p, .login-container > p {
        font-size: 1.2em;
    }
    
    .signup-container button,
     .login-container button, 
     .edit-account-section button, 
     .edit-account-container button{
        font-size: 1.5em;
        padding: 1.5em 0;
    }
    .hidden-sm {
        display: block;
    }
    .hidden-md {
        display: none;
    }
    .appointment-container > section > h2 {
        font-size: 1.8em;
    }
    .fa {
        max-width: 5em;
        font-size: 1.8em;
    }
    .account-heading > h2 {
        font-size: 2em;
    }
    .account-heading > p{
        font-size: 1.2em;
    }
    .account-details>div>p:first-child {
        font-size: 1.5em;
    }
    .account-details>div {
        margin: 2em 0;
        line-height: 40px;
        font-size: 1.2em;
    }
    .account-details > a {
        font-size: 1.2em;
    }
    .edit-account-container > form {
        margin: 3em 0;
    }
}