.fadeInOut{
    animation-name: imgFadeInOut; 
    animation-duration: 10s; 
    animation-timing-function:ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
  }
  @keyframes imgFadeInOut {
    0% {
      opacity:1;
    }
    25% {
      opacity:0;
    }
    50% {
      opacity:0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .image-container img:nth-of-type(1) {
    animation-delay: 10s;
  }
  .image-container img:nth-of-type(2) {
    animation-delay: 8s;
  }
  .image-container img:nth-of-type(3) {
    animation-delay: 6s;
  }
  .image-container img:nth-of-type(4) {
    animation-delay: 4s;
  }
  .image-container img:nth-of-type(5) {
    animation-delay: 2s;
  }

.page-section.best .card-container{
    position: relative;
    right: 0px;
    animation: scroll-ltr 8s linear 2s infinite alternate;
}

@keyframes scroll-ltr{
    0% {
        transform: translateX(0px);
    }
    10%{
        transform: translateX(-80px);
    }
    20%{
        transform: translateX(-160px);
    }
    30%{
        transform: translateX(-240px);
    }
    40% {
        transform: translateX(-320px);
    }
    50%{
        transform: translateX(-400px);
    }
    60% {
        transform: translateX(-480px);
    }
    70%{
        transform: translateX(-560px);
    }
    80%{
        transform: translateX(-640px);
    }
    90%{
        transform: translateX(-720px);
    }
    100%{
        transform: translateX(-800px);
    }
}
@keyframes navbarline1_open {
  from {
    transform: none;
  }
  to {
    transform: rotate(45deg) translate(.5em, .3em);
  }
  
}
@keyframes navbarline3_open {
  from {
    transform: none;
  }
  to {
    transform: rotate(-45deg) translate(0em, .2em);
  }
  
}
@keyframes navbarline1_close {
  from {
    transform: rotate(45deg) translate(.5em, .3em);
  }
  to {
    transform: none;
  }
  
}
@keyframes navbarline3_close {
  from {
    transform: rotate(-45deg) translate(0em, .2em);
  }
  to {
    transform: none;
  }
  
}

@media screen and (min-width:768px) and (max-width:1200px){
  @keyframes navbarline1_open {
    from {
      transform: none;
    }
    to {
      transform: rotate(45deg) translate(.7em, .3em);
    }
    
  }
  @keyframes navbarline3_open {
    from {
      transform: none;
    }
    to {
      transform: rotate(-45deg) translate(.1em, .2em);
    }
    
  }
}